import React, { useState, useRef, useEffect } from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const [isSlantNormal, setIsSlantNormal] = useState(true)
  const isSlantNormalRef = useRef(isSlantNormal)
  isSlantNormalRef.current = isSlantNormal

  const toggleSlant = () => {
    setIsSlantNormal(!isSlantNormalRef.current)
  }
  
  const toggleSlantSequence = () => {
    toggleSlant()
    setTimeout(toggleSlant, 300)
    setTimeout(toggleSlant, 600)
    setTimeout(toggleSlant, 900)
    setTimeout(toggleSlant, 1200)
    setTimeout(toggleSlantSequence, 5000)
  }

  useEffect(() => {
    toggleSlantSequence()
  }, [])

  return (
    <Layout>
      <SEO title="Graphic designing | Image making | Hip shaking" />
      <Hero>
        <p>Graphic designing</p>
        <p>Art directing</p>
        <p>Brand thinking</p>
        <p>Copy writing</p>
        <p>Image making</p>
        {isSlantNormal && <SlantRight onClick={() => setIsSlantNormal(false)}>Hip shaking</SlantRight>}
        {!isSlantNormal && <SlantLeft onClick={() => setIsSlantNormal(true)}>Hip shaking</SlantLeft>}
      </Hero>
      <Details>
        <p><a href="mailto:sam@samuelbyrnes.com" style={{ color: 'inherit', textDecoration: 'none' }}>sam@samuelbyrnes.com</a></p>
        <p>Dial <a href="tel:+61-438-160-109" style={{ color: 'inherit', textDecoration: 'none' }}>+61 <Bump>(</Bump>0<Bump>)</Bump>438 160 109</a></p>
        <p>Social <a href="https://instagram.com/samuelbyrnes" target="_blank" style={{ color: 'inherit', textDecoration: 'none' }}>@samuelbyrnes</a></p>
        <p>&nbsp;</p>
        <p>If you'd like to chat about a project or commission get in touch.</p>
      </Details>
    </Layout>
  )
}

const Hero = styled.div`
  height: 50%;
  text-align: center;
  font: Bold Condensed 58px/57px franklin-gothic-urw-comp;
  letter-spacing: -0.87px;
  color: #2B2E34;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 2px;
  }

  @media only screen and (max-width: 700px) {
    font: Bold Condensed 47px/48px franklin-gothic-urw-comp;
  }
`

const SlantRight = styled.span`
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-20deg, 0deg);
  user-select: none;
  cursor: pointer;
`

const SlantLeft = styled.span`
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(20deg, 0deg);
  user-select: none;
  cursor: pointer;
`

const Details = styled.div`
  height: 50%;
  text-align: center;
  font: Bold Condensed 58px/57px franklin-gothic-urw-comp;
  letter-spacing: -0.87px;
  color: #2B2E34;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 2px;
  }

  @media only screen and (max-width: 700px) {
    font: Bold Condensed 32px/31px franklin-gothic-urw-comp;
  }
`

const Bump = styled.div`
  display: inline-block;
  transform: translateY(-4px);

  @media only screen and (max-width: 700px) {
    transform: translateY(-2px);
  }
`

export default IndexPage
